import React, { useState, useEffect } from 'react';

const RandomVideo = () => {
    const [videoData, setVideoData] = useState([]);
    const [randomVideo, setRandomVideo] = useState(null);

    // Function to fetch and parse videos from output.jsonlines
    const fetchVideos = async () => {
        try {
            const response = await fetch('/output.json');
            const text = await response.text();
            const lines = text.trim().split('\n');
            const data = lines.map(JSON.parse);
            setVideoData(data);
        } catch (error) {
            console.error("Failed to fetch or parse the videos:", error);
        }
    };

    // Function to select a random video
    const getRandomVideo = () => {
        if (videoData.length > 0) {
            const randomIndex = Math.floor(Math.random() * videoData.length);
            setRandomVideo(videoData[randomIndex]);
        }
    };

    // Fetch videos on component mount
    useEffect(() => {
        fetchVideos();
    }, []);

    return (
        <div className="max-w-md mx-auto p-4">
            {randomVideo && (
                <div className="space-y-4">
                    <div className="aspect-w-16 aspect-h-9 bg-gray-200 rounded-lg overflow-hidden shadow-lg cursor-pointer" onClick={() => window.open(randomVideo.webpage_url, "_blank")}>
                        <img src={randomVideo.thumbnail} alt={randomVideo.title} className="object-cover w-full h-full" />
                    </div>
                    <p className="text-lg font-semibold">{randomVideo.title}</p>
                </div>
            )}
            <button className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition-colors" onClick={getRandomVideo}>Random Video</button>
        </div>
    );
};

export default RandomVideo;